nav {
  min-height: 10vh;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 2rem 0rem;
  button {
    background: none;
    border: 2px solid rgb(54, 54, 54);
    font-size: 1rem;
    padding: 0.65rem;
    transition: 0.5s ease;
    z-index: 10;
    &:hover {
      background: rgb(54, 54, 54);
      color: white;
    }
  }
}

@media screen and (max-width: 768px) {
  nav {
    padding-bottom: 0rem;
  }
}
