@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Lato", sans-serif;
}

h1,
h2,
h3,
h4 {
  color: rgb(50, 50, 50);
}

button,
svg {
  cursor: pointer;
}
img {
  pointer-events: none;
}

.App {
  transition: all 0.5s ease;
}

.App.active {
  margin-left: 25%;
}

@import "./nav";
@import "./song";
@import "./player";
@import "./library";
